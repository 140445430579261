import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import { FaPhoneAlt, FaRegWindowClose } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "gatsby";
import ButtonAction from "./ButtonAction";

const MobileMenuItem = ({ to, children, className }) => {
  return (
    <>
      {to !== undefined ? (
        <Link
          to={to}
          className={`button-action ${className}`}
          activeClassName="bg-actionBlue text-white"
        >
          <div className="w-full pl-12 py-3 underline">{children}</div>
        </Link>
      ) : (
        <div className={className}>
          <div className="w-full pl-12 py-3">{children}</div>
        </div>
      )}
    </>
  );
};

export const NavBar = ({
  direction,
  children,
  className,
  logoClassName,
  supportMobile,
  isHome,
}) => {
  const ChildObj = React.Children.toArray(children);

  const NavLogoObj = ChildObj.find((c) => {
    return c.type.name === "NavLogo" || c.props.name === "NavLogo";
  });
  const NavItems = ChildObj.filter((c) => {
    return c.type.name === "NavItem" || c.props.name === "NavItem";
  });

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      window.scrollTo(0, 0);
    };

    if (showMenu) window.addEventListener("scroll", onScroll);
    else window.removeEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  });

  return (
    <>
      <div className="flex lg:hidden absolute z-40 top-8 right-6 w-8 h-8">
        <button
          className={`flex items-center justify-center rounded-md ${
            isHome ? "text-white" : "text-gray1"
          } focus:outline-none`}
          onClick={() => setShowMenu(true)}
        >
          <GiHamburgerMenu className="text-3xl" />
        </button>
      </div>

      {/* MOBILE MENU */}
      <div
        className={`flex lg:hidden absolute top-0 w-full h-screen transition-all duration-500 ease-in-out bg-white ${
          showMenu ? "right-0" : "-right-screen"
        } ${
          direction === "right"
            ? "justify-start lg:justify-end"
            : "justify-start"
        } z-50`}
      >
        <button
          className="items-center justify-center absolute z-50 w-8 h-8 top-2 left-2 text-red-700 focus:outline-none"
          onClick={() => setShowMenu(false)}
        >
          <FaRegWindowClose className="text-2xl" />
        </button>
        <div className="pt-12 flex flex-col w-full">
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/">
            HOME
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold">
            PRODUCTS
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/hardwood-lumber">
            Hardwood Lumber
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/hardwood-plywood">
            Hardwood Plywood
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/lacquered-boards">
            Lacquered Panels
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/textured-melamine">
            Textured Melamine
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/melamine-panels">
            Melamine
          </MobileMenuItem>
          <MobileMenuItem className="bg-white border-b border-gray1" to="/products/mdf">
            MDF
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/about">
            ABOUT
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/blog">
            BLOG
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/forms">
            FORMS
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/jobs">
            JOBS
          </MobileMenuItem>
          <MobileMenuItem className="bg-gray6 border-b border-white font-bold" to="/contact">
            CONTACT
          </MobileMenuItem>
        </div>
      </div>

      <div className={`${className}`}>
        <div className={logoClassName}>{NavLogoObj}</div>
        <div
          className={`${
            showMenu ? "w-screen h-screen lg:w-0 lg:h-0" : ""
          } absolute left-0 top-0 bg-black lg:bg-transparent bg-opacity-50 lg:static lg:flex-grow flex`}
        >
          <div
            className={`hidden sm:flex flex-row w-full pr-20 ${
              direction === "right"
                ? "justify-start lg:justify-end"
                : "justify-start"
            }`}
          >
            <div className="hidden lg:flex flex-row pt-10 gap-y-10">
              {NavItems}
            </div>
          </div>
        </div>

        <div className="hidden lg:flex items-center justify-center h-32">
          <ButtonAction
            href="tel:(909)-287-7906"
            newWindow={false}
            className={`flex items-center justify-center px-12 ${
              isHome
                ? "text-white text-lg border-2 border-white bg-black bg-opacity-30 hover:bg-opacity-75"
                : "text-white text-lg bg-actionBlue"
            } max-h-14`}
          >
            <FaPhoneAlt className="inline mr-4" />
            {` (909) 287-7906`}
          </ButtonAction>
        </div>
      </div>
    </>
  );
};

export const NavItem = ({ children, link, className }) => {
  return (
    <div
      className={
        className
          ? className
          : "flex justify-center items-center cursor-pointer px-1 py-4 font-sans text-sm"
      }
    >
      <Link
        className="button-action"
        to={link}
        activeClassName="text-actionBlue underline"
      >
        {children}
      </Link>
    </div>
  );
};

export class NavLogo extends Component {
  render() {
    const { link } = { ...this.props };

    const ImageContainer = styled.div`
      displayx: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: url(${this.props.image});
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      width: ${this.props.width !== undefined
        ? this.props.width
        : this.props.height !== undefined
        ? this.props.height
        : 0};
      height: ${this.props.height !== undefined
        ? this.props.height
        : this.props.width !== undefined
        ? this.props.width
        : 0};
    `;
    return (
      <Link to={link ? link : "/"}>
        <ImageContainer>&nbsp;</ImageContainer>
      </Link>
    );
  }
}
