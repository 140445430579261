import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

const HeadData = ({
  title,
  description,
  keywords,
  image,
  pathname,
  article,
  isHome,
}) => {
  const data = useStaticQuery(graphql`
    query HeadDataQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: siteUrl
          defaultImage: image
        }
      }
    }
  `);

  const seo = {
    title: title || data.site.siteMetadata.defaultTitle,
    description: description || data.site.siteMetadata.defaultDescription,
    image: `${data.site.siteMetadata.siteUrl}${
      image || data.site.siteMetadata.defaultImage
    }`,
    url: `${data.site.siteMetadata.siteUrl}${pathname || "/"}`,
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={isHome ? null : data.site.siteMetadata.titleTemplate}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {keywords && <meta name="keywords" content={keywords} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
    </Helmet>
  );
};

export default HeadData;

HeadData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  isHome: PropTypes.bool,
};

HeadData.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  isHome: false,
};
